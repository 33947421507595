(function () {
	'use strict';

	angular.module('app.services')
		.factory('DepotModel', function($q, $http) {

			var methods = {
				all: function() {
					var d = $q.defer();

					$http.get(apiUrl + '/depots')
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
                manager: function(manager) {
                    var d = $q.defer();

                    $http.get(apiUrl + '/depots/manager/' + manager)
                        .success(function(response) {
                            d.resolve(response);
                        })
                        .error(function(error) {
                            d.reject(error);
                        });

                    return d.promise;
                },
				paginate: function(page) {
					var d = $q.defer();
					$http.get(apiUrl + '/depots?page=' + page)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				get: function($id) {
					var d = $q.defer();

					$http.get(apiUrl + '/depots/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				new: function($data) {
					var d = $q.defer();

					$http.post(apiUrl + '/depots', $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				update: function($id, $data) {
					var d = $q.defer();

					$http.put(apiUrl + '/depots/' + $id, $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				delete: function($id) {
					var d = $q.defer();

					$http.delete(apiUrl + '/depots/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				fields: function() {
					return [
						{
							title : 'Name',
							model : 'name',
							type  : 'text'
						}
					];
				}
			};

			return methods;
		});
})();