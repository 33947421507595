angular.module('app.controllers').controller('NoticesCreateController', function ($scope, $state, $auth, NoticeModel, DepotModel, ModalService) {
    'use strict';

	$scope.notice = {
	};
	NoticeModel.getNoticeTypes().then(function($data){
		$scope.noticeTypes = $data;
	});

	DepotModel.all().then(function($data){
		$scope.depots = $data;
	});

	$scope.edit=false;
	if($state.params.id != null){
		$scope.edit = true;
		NoticeModel.get($state.params.id).then(function($data){
			$scope.notice = $data;
			//Format data for groups multiselect
			$scope.notice.depots = $data.depots.map(function(depot){
				return String(depot.id);
			});

		}, function (error) {
			console.error(error);
		});
	}

	$scope.submitNotice= function(){
		if($scope.validateDocument()){
			if($scope.edit){
				NoticeModel.update($state.params.id, $scope.notice).then(function($data){
					$state.go('notices', {});
				}, function (error) {
					console.error(error);
				});
			}else{
				NoticeModel.new($scope.notice).then(function (data) {
					// if success, redirect to user state
					$state.go('notices', {});
				}, function (error) {
					console.error(error);
				});
			}
		}
	};

	$scope.validateDocument = function(){
		if($scope.notice.document_id != null){
			return true;
		}
		ModalService.showModal({
			templateUrl: "/views/app/modals/message-modal/message-modal.html",
			controller: "MessageModalController",
			inputs: {
				message: "A valid document is required"
			}
		});
		return false;
	}


});