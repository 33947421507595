(function () {
	'use strict';

	angular.module('app.services')
		.factory('ReportModel', function($q, $http) {

			var methods = {

				getReport: function($data) {
					var d = $q.defer();

					$http.post(apiUrl + '/reports', $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				}
			};

			return methods;
		});
})();