
'use strict';
angular.module('app.directives').directive('depotsTable', function($rootScope){

	return {
		restrict: 'E',
		scope: {
			depots: '=',
			searchParams: '='
		},
		templateUrl: "/views/app/directives/depots-table/depots-table.html",
		link: function ($scope, element, attrs) {

		},
		controller: function($scope, DepotModel, ModalService, $rootScope){
			$scope.isAdmin = $rootScope.isAdmin;
			$scope.pageSize=10;
			$scope.currentPage = 1;

			$scope.getDepots = function() {
				DepotModel.all().then(function($data){
					$scope.depots = $data;
				}, function (error) {
					console.error(error);
				});
			};
			$scope.getDepots();

			$scope.deleteDepot = function($id){
				ModalService.showModal({
					templateUrl: "/views/app/modals/delete-modal/delete-modal.html",
					controller: "DeleteModalController",
					inputs: {
						deleteMethod: function(id, model){
							model.delete(id).then(function($data){
								$scope.getDepots();
							}, function (error) {
								console.error(error);
							})
						},
						id: $id,
						model: DepotModel
					}
				});

			}
		}
	};

});
