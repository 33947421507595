angular.module('app.controllers').controller('DeleteModalController', function($scope, close, deleteMethod, id, model) {
	'use strict';

	$scope.deleteItem = deleteMethod;
	$scope.id = id;
	$scope.model = model;

	$scope.dismissModal = function(result) {
		close(result, 0);
	};

});
