
angular.module('app.controllers').controller('LoginController', function ($scope, $state, $auth) {
	'use strict';
	$scope.failLogin = false;
	if ($auth.isAuthenticated()) {
		$state.transitionTo('traincrew-reports');
	}
	$scope.login = {
		email: "",
		password: ""
	};
	$scope.authenticate = function () {
		$auth.login($scope.login).then(function (data) {
			// if success, redirect to user state
			$state.go('traincrew-reports', {});
		}, function (error) {
			console.error(error);
			if (error.status == 401){
				$scope.failLogin = true;
			}
		});
	}
});
