var apiUrl = '';

(function(){
    "use strict";

	// if (window.location.host.indexOf('.dev') == -1) {
	// 	apiUrl = 'http://scotrailcms.xdesign365.com/api/v1/cms';
	// } else {
	// 	apiUrl = 'http://app.scotrail.dev/api/v1/cms';
	// }
    apiUrl = '/api/v1/cms';

    angular.module('app.routes', ['ui.router', 'satellizer']).config( function($stateProvider, $authProvider, $urlRouterProvider ) {

        var getHeaderView = function(viewName){
            return '/views/app/header/' + viewName + '.html';
        };
		    var getView = function(viewName){
            return '/views/app/' + viewName + '/' + viewName + '.html';
        };

		$authProvider.loginUrl = '/api/auth';
        $urlRouterProvider.otherwise('/login');

        $stateProvider
        .state('login', {
            url: '/login',
            views: {
                header: {
                    templateUrl: getHeaderView('login')
                },
                main: {
                    templateUrl: getView('login'),
                    controller: 'LoginController'
                }
            }
        }).state('traincrew-reports', {
            url: '/traincrew-reports',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('traincrew-reports'),
                    controller: 'TraincrewReportsController'
                }
            },
			data:{
				authRoles: ['admin', 'manager']
			}
        }).state('notices-create', {
            url: '/notices/create',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('notices-create'),
                    controller: 'NoticesCreateController'
                }
            },
			data:{
				authRoles: ['admin','manager']
			}
        }).state('notices', {
            url: '/notices',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('notices'),
                    controller: 'NoticesController'
                }
            },
			data:{
				authRoles: ['admin', 'manager']
			}
        }).state('notices-edit', {
			url: '/notices/edit/:id',
			views: {
				header: {
					templateUrl: getHeaderView('main')
				},
				main: {
					templateUrl: getView('notices-create'),
					controller: 'NoticesCreateController'
				}
			},
			data:{
				authRoles: ['admin', 'manager']
			}
		}).state('depots', {
            url: '/depots',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('depots'),
                    controller: 'DepotsController'
                }
            },
			data:{
				authRoles: ['admin', 'manager']
			}
        }).state('depots-create', {
            url: '/depots/create',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('depots-create'),
                    controller: 'DepotsCreateController'
                }
            },
			data:{
				authRoles: ['admin']
			}
        }).state('depots-edit', {
			url: '/depots/edit/:id',
			views: {
				header: {
					templateUrl: getHeaderView('main')
				},
				main: {
					templateUrl: getView('depots-create'),
					controller: 'DepotsCreateController'
				}
			},
			data:{
				authRoles: ['admin']
			}
		}).state('managers', {
            url: '/managers',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('managers'),
                    controller: 'ManagersController'
                }
            }
        }).state('people', {
            url: '/people',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('people'),
                    controller: 'PeopleController'
                }
            },
			data:{
				authRoles: ['admin', 'manager']
			}
        }).state('people-create', {
            url: '/people/create',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('people-create'),
                    controller: 'PeopleCreateController'
                }
            },
			data:{
				authRoles: ['admin', 'manager']
			}
        }).state('people-edit', {
			url: '/people/edit/:id',
			views: {
				header: {
					templateUrl: getHeaderView('main')
				},
				main: {
					templateUrl: getView('people-create'),
					controller: 'PeopleCreateController'
				}
			},
			data:{
				authRoles: ['admin', 'manager']
			}
		}).state('configuration', {
            url: '/configuration',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('configuration'),
                    controller: 'ConfigurationController'
                }
            },
			data:{
				authRoles: ['admin']
			}
        }).state('configuration-create', {
            url: '/configuration/create',
            views: {
                header: {
                    templateUrl: getHeaderView('main')
                },
                main: {
                    templateUrl: getView('configuration-create'),
                    controller: 'ConfigurationCreateController'
                }
            },
			data:{
				authRoles: ['admin']
			}
        }).state('configuration-edit', {
			url: '/configuration/edit/:id',
			views: {
				header: {
					templateUrl: getHeaderView('main')
				},
				main: {
					templateUrl: getView('configuration-create'),
					controller: 'ConfigurationCreateController'
				}
			},
			data:{
				authRoles: ['admin']
			}
		}).state('email', {
			url: '/email',
			views: {
				header: {
					templateUrl: getHeaderView('main')
				},
				main: {
					templateUrl: getView('email'),
					controller: 'EmailController'
				}
			},
			data:{
				authRoles: ['admin']
			}
		}).state('push-notifications', {
          url: '/push-notifications',
          views: {
            header: {
              templateUrl: getHeaderView('main')
            },
            main: {
              templateUrl: getView('push-notifications'),
              controller: 'PushNotificationsController'
            }
          },
          data:{
            authRoles: ['admin', 'manager']
          }
        });

    });
})();
