angular.module('app.controllers').controller('TraincrewReportsController', function($scope, $state, $auth, ManagerModel, DepotModel, NoticeModel, ReportModel) {
    'use strict';

	$scope.report = {};
	$scope.show = false;

	ManagerModel.all().then(function($data){
		$scope.managers = $data;
	});

	DepotModel.all().then(function($data){
		$scope.depots = $data;
		$scope.report.depot = $data[0].id;
	});

	NoticeModel.all().then(function($data){
		$scope.notices = $data;
	});

    $scope.managerDepots = function(){
        //Check if the manager has a length (exists as a selection), then make the call for the correct depot list, otherwise repopulate the depot list with all depots.
        if ($scope.report.manager.length > 0) {
            DepotModel.manager($scope.report.manager).then(function($data){
                $scope.depots = $data;
            });
        } else {
            DepotModel.all().then(function($data){
                $scope.depots = $data;
                $scope.report.depot = $data[0].id;
            });
        }
    };

    $scope.depotsNotices = function(){
        NoticeModel.notices($scope.report.depot).then(function($data){
            $scope.notices = $data;
        });
    };

	$scope.getReport = function(){
		ReportModel.getReport($scope.report).then(function($data){
			$scope.reportData = $data;
			$scope.show = true;
			$scope.calculateMetrics($scope.reportData);
		});
	};

	$scope.max = 100;
	$scope.n = 0;
	$scope.d = 0;
	$scope.ds = 0;
	$scope.do = 0;
	$scope.dso = 0;

	$scope.calculateMetrics = function($data){
		var countDownloadedSignedOpened = 0,
            countDownloadedSigned = 0,
            countDownloadedOpened = 0,
            countDownloaded = 0,
            countNone = 0;

		//for each item in the data, find out which timestamps it has and count
		for (var i = 0; i <$data.length;i++){
			if ($scope.validateMetrics($data[i], true, true, true)) {
				countDownloadedSignedOpened++;
			} else if ($scope.validateMetrics($data[i], true, true, false)) {
				countDownloadedSigned++;
			} else if ($scope.validateMetrics($data[i], true, false, true)) {
				countDownloadedOpened++;
			} else if ($scope.validateMetrics($data[i], true, false, false)) {
				countDownloaded++;
			} else if ($scope.validateMetrics($data[i], false, false, false)) {
				countNone++;
			}
		}

		//for each of the report metrics, calculate the overall percentage, then store in $scope variable
		//which is then picked up by report object on html
		$scope.downloadedSignedOpened = $scope.calculatePercentage(countDownloadedSignedOpened, $data.length);
		$scope.downloadedSigned = $scope.calculatePercentage(countDownloadedSigned, $data.length);
		$scope.downloadedOpened = $scope.calculatePercentage(countDownloadedOpened, $data.length);
		$scope.downloaded = $scope.calculatePercentage(countDownloaded, $data.length);
		$scope.none = $scope.calculatePercentage(countNone, $data.length);
	};

	$scope.validateMetrics = function(data, isDownloaded, isSigned, isOpened){
		if ($scope.isMetricNull(data.downloaded_at) == isDownloaded && $scope.isMetricNull(data.signed_at) == isSigned && $scope.isMetricNull(data.opened_at) == isOpened){
			return true;
		}
		return false;
	};

    $scope.isMetricNull = function(metric){
		if (metric !== null && metric !== undefined) {
			return true;
		}
		return false;
	};

    $scope.calculatePercentage = function(count, dataLength){
        if (count > 0) {
            return ((count / dataLength) * 100).toFixed(2);
        }

        return count.toFixed(2);
	};
});