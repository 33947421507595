angular.module('app.controllers').controller('ConfigurationCreateController', function ($scope, $state, ConfigurationModel) {
    'use strict';

	$scope.configuration = {
		key: "",
		value: ""
	};

	$scope.edit=false;
	if($state.params.id != null){
		$scope.edit = true;
		ConfigurationModel.get($state.params.id).then(function($data){
			$scope.configuration = $data;
		}, function (error) {
			console.error(error);
		});
	}

    $scope.submitConfiguration = function(){
		if($scope.edit){
			ConfigurationModel.update($state.params.id, $scope.configuration).then(function($data){
				$state.go('configuration', {});
			}, function (error) {
				console.error(error);
			});
		}else{
			ConfigurationModel.new($scope.configuration).then(function (data) {
				// if success, redirect to user state
				$state.go('configuration', {});
			}, function (error) {
				console.error(error);
			});
		}
    }

});