angular.module('app.controllers').controller('DepotsCreateController', function ($scope, $state, $auth, DepotModel) {
    'use strict';

	$scope.depot = {
		name: ""
	};
	$scope.edit=false;
	if($state.params.id != null){
		$scope.edit = true;
		DepotModel.get($state.params.id).then(function($data){
			$scope.depot = $data;
		}, function (error) {
			console.error(error);
		});
	}

	$scope.submitDepot= function(){
		if($scope.edit){
			DepotModel.update($state.params.id, $scope.depot).then(function($data){
				$state.go('depots', {});
			}, function (error) {
				console.error(error);
			});
		}else{
			DepotModel.new($scope.depot).then(function (data) {
				// if success, redirect to user state
				$state.go('depots', {});
			}, function (error) {
				console.error(error);
			});
		}
	}


});