(function () {
	'use strict';

	angular.module('app.services')
		.factory('RoleModel', function($q, $http) {

			var methods = {
				all: function() {
					var d = $q.defer();

					$http.get(apiUrl + '/roles')
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				get: function($id) {
					var d = $q.defer();

					$http.get(apiUrl + '/roles/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				}
			};

			return methods;
		});
})();