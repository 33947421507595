
angular.module('app.controllers').controller('MainController', function ($scope, $state, $auth) {
    'use strict';

    this.logout = function() {
        $auth.logout();
        $state.transitionTo('login');
    };


});
