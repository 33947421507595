
'use strict';
angular.module('app.directives').directive('fileUploader', function(FileModel){

	return {
		restrict: 'E',
		scope: {
			uploadedFile: '=',
			uploading: '=',
			fileId: '=',
			fileType: '@'
		},
		templateUrl: "/views/app/directives/file-uploader/file-uploader.html",
		link: function ($scope, element, attrs) {


		},
		controller: function($scope, FileUploader, ModalService){

			if($scope.fileType == 'document'){
				$scope.allowedTypes = ['application/pdf'];
			}else if($scope.fileType == 'image'){
				$scope.allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];
			}else{
				$scope.allowedTypes = [];
			}


			$scope.upload = function () {
			    document.getElementById("fileInput").click();
			};

			$scope.uploadUserImage = function(element){
				var file = element.files[0];
				var validType = false
				for(var i = 0; i<$scope.allowedTypes.length; i++){
					if($scope.allowedTypes[i] == file.type){
						validType = true;
					}
				}
				if(validType){
					$scope.uploading = true;
				    if(file != null && file != undefined){
				        FileModel.uploadUserImage(file).then(function(response){
				            $scope.uploadedFile = response;
				            $scope.fileId = response.id;
				            $scope.uploading = false;
				        }, function (error){
				            console.error(error);
				        });
				    }
				}else{
					ModalService.showModal({
						templateUrl: "/views/app/modals/message-modal/message-modal.html",
						controller: "MessageModalController",
						inputs: {
							message: "Cannot upload this file type"
						}
					});
				}
			}

		}
	};

});