
'use strict';
angular.module('app.directives').directive('noticeTable', function(){

	return {
		restrict: 'E',
		scope: {
			searchParams: '='
		},
		templateUrl: "/views/app/directives/notices-table/notices-table.html",
		link: function ($scope, element, attrs) {

		},
		controller: function($scope, NoticeModel, ModalService){
			$scope.pageSize=10;
			$scope.currentPage = 1;

			$scope.getNotices = function() {
				NoticeModel.all().then(function($data){
					$scope.notices = $data;

				}, function (error) {
					console.error(error);
				});
			};
			$scope.getNotices();

			$scope.deleteNotice = function($id){
				ModalService.showModal({
					templateUrl: "/views/app/modals/delete-modal/delete-modal.html",
					controller: "DeleteModalController",
					inputs: {
						deleteMethod: function(id, model){
							model.delete(id).then(function($data){
								$scope.getNotices();
							}, function (error) {
								console.error(error);
							})
						},
						id: $id,
						model: NoticeModel
					}
				});

			}
		}
	};

});