(function () {
	'use strict';

	angular.module('app.services')
		.factory('NoticeModel', function($q, $http) {

			var methods = {
				all: function() {
					var d = $q.defer();

					$http.get(apiUrl + '/notices')
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
                notices: function(depot) {
                    var d = $q.defer();

                    $http.get(apiUrl + '/notices/depots/' + depot)
                        .success(function(response) {
                            d.resolve(response);
                        })
                        .error(function(error) {
                            d.reject(error);
                        });

                    return d.promise;
                },
				paginate: function(page) {
					var d = $q.defer();
					$http.get(apiUrl + '/notices?page=' + page)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				get: function($id) {
					var d = $q.defer();

					$http.get(apiUrl + '/notices/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				getNoticeTypes: function(){
					var d = $q.defer();

					$http.get(apiUrl + '/noticetypes')
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				new: function($data) {
					var d = $q.defer();

					$http.post(apiUrl + '/notices', $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				update: function($id, $data) {
					var d = $q.defer();

					$http.put(apiUrl + '/notices/' + $id, $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				delete: function($id) {
					var d = $q.defer();

					$http.delete(apiUrl + '/notices/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				fields: function() {
					return [
						{
							title : 'Key',
							model : 'key',
							type  : 'text'
						},
						{
							title: 'Value',
							model: 'value',
							type: 'text'
						}
					];
				}
			};

			return methods;
		});
})();