angular.module('app.controllers').controller('PeopleController', function ($scope, $state, $auth, RoleModel, DepotModel) {
    'use strict';

	RoleModel.all().then(function($data){
		$scope.roles = $data;
	});

	DepotModel.all().then(function($data){
		$scope.depots = $data;
	});


});