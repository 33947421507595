
'use strict';
angular.module('app.directives').directive('reportTable', function(){

	return {
		restrict: 'E',
		scope: {
			reportData: '='
		},
		templateUrl: "/views/app/directives/report-table/report-table.html",
		link: function($scope, element, attrs) {

		},
		controller: function($scope, ModalService){
			$scope.pageSize = 10;
			$scope.currentPage = 1;
		}
	};

});