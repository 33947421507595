angular.module('app.controllers').controller('EmailController', function ($scope, $state, $auth, $http) {
	'use strict';

	$scope.data = {};
	$scope.responseReceived =false;
	$scope.response = "";
	$scope.error = false;

	$scope.dailyReport = function(){
		if($scope.data.email != null && $scope.data.email != "" && $scope.data.email != undefined){
			$http.post(apiUrl + "/email/daily", $scope.data)
				.success(function(response) {
					$scope.error = false;
					$scope.response = response.message;
					$scope.responseReceived = true;
				})
				.error(function(error) {
					$scope.error = true;
					$scope.response = error;
					$scope.responseReceived = true;
				});
		}else{
			$scope.responseReceived =true;
			$scope.response = "Please enter a valid email address";
			$scope.error = true;
		}
	}

	$scope.weeklyReport = function(){
		if($scope.data.email != null && $scope.data.email != "" && $scope.data.email != undefined) {
			$http.post(apiUrl + "/email/weekly", $scope.data)
				.success(function (response) {
					$scope.error = false;
					$scope.response = response.message;
					$scope.responseReceived = true;
				})
				.error(function (error) {
					$scope.error = true;
					$scope.response = error;
					$scope.responseReceived = true;
				});
		}else{
			$scope.responseReceived =true;
			$scope.response = "Please enter a valid email address";
			$scope.error = true;
		}
	}

});