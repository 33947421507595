(function () {
	'use strict';

	angular.module('app.services')
		.factory('ManagerModel', function($q, $http) {

			var methods = {
				all: function() {
					var d = $q.defer();

					$http.get(apiUrl + '/managers')
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				paginate: function(page) {
					var d = $q.defer();
					$http.get(apiUrl + '/managers?page=' + page)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				get: function($id) {
					var d = $q.defer();

					$http.get(apiUrl + '/managers/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				new: function($data) {
					var d = $q.defer();

					$http.post(apiUrl + '/managers', $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				update: function($id, $data) {
					var d = $q.defer();

					$http.put(apiUrl + '/managers/' + $id, $data)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				delete: function($id) {
					var d = $q.defer();

					$http.delete(apiUrl + '/managers/' + $id)
						.success(function(response) {
							d.resolve(response);
						})
						.error(function(error) {
							d.reject(error);
						});

					return d.promise;
				},
				fields: function() {
					return [
						{
							title : 'Name',
							model : 'name',
							type  : 'text'
						}
					];
				}
			};

			return methods;
		});
})();