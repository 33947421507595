'use strict';
(function() {

var app = angular.module('app', [
	'app.controllers',
	'app.filters',
	'app.services',
	'app.directives',
    'app.routes',
    'app.config',
	'angularModalService',
	'ui.router',
	'restangular',
	'angularUtils.directives.dirPagination',
	'ngAnimate',
	'angularFileUpload',
	'angular-svg-round-progressbar',
	'angular-jwt'
]);

    angular.module('app.routes', []);
    angular.module('app.controllers', []);
    angular.module('app.filters', []);
    angular.module('app.services', []);
    angular.module('app.directives', []);
    angular.module('app.config', []);
})();

angular.module('app').run(function($rootScope, $state, $auth, jwtHelper) {
	// $stateChangeStart is fired whenever the state changes.
	$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
		//is there a valid token in local storage
		if($auth.isAuthenticated()){
			var token = window.localStorage.getItem('satellizer_token');
			var payload = jwtHelper.decodeToken(token);
			//get roles of user from token
			$rootScope.roles = payload.roles;
			$rootScope.user_id = payload.sub;
			//check to see if the user has roles admin, manager or user and set
			//global variables based on permissions
			$rootScope.isAdmin = $rootScope.roles.indexOf("admin") != -1;
			$rootScope.isManager = $rootScope.roles.indexOf("manager") != -1;
			$rootScope.isUser = $rootScope.roles.indexOf("user") != -1;
		}

		//if permissions have been set for state route
		if(toState.data != null && toState.data.authRoles != []){
			//check to see user has permissions to access route
			var permissions = toState.data.authRoles;
			var found = false;
			for(var i=0;i<$rootScope.roles.length;i++){
				if(permissions.indexOf($rootScope.roles[i]) != -1){
					found = true;
				}
			}
			//if not, then prevent transition to state
			if(!found){
				event.preventDefault();
				console.error("Unable to access view");
			}
		}
	});
});
