angular.module('app.controllers').controller('PeopleCreateController', function ($scope, $state, $auth, PeopleModel, DepotModel, ManagerModel, RoleModel, ModalService) {
    'use strict';
	$scope.uploading = false;

	$scope.person = {
		email: "",
		name: "",
		password: "",
		short_name: "",
		description: "",
		telephone: "",
		image_id: null,
		image: null,
		roles: 1
	};

	$scope.confirmPassword = "";

	DepotModel.all().then(function($data){
		$scope.depots = $data;
	});
	RoleModel.all().then(function($data){
		$scope.roles = $data;
	});

	$scope.edit=false;
	if($state.params.id != null){
		$scope.edit = true;
		PeopleModel.get($state.params.id).then(function($data){
			$scope.person = $data;
			//Format data for depots multiselect
			$scope.person.depots = $data.depots.map(function(depot){
				return String(depot.id);
			});
			//Format data for roles radio groupwithin
			$scope.person.roles = $data.roles[0].id;
		}, function (error) {
			console.error(error);
		});
	}

	$scope.submitPerson= function(){
		if(!$scope.edit || $scope.person.password || $scope.confirmPassword){
			if(!$scope.validatePassword()){
				ModalService.showModal({
					templateUrl: "/views/app/modals/message-modal/message-modal.html",
					controller: "MessageModalController",
					inputs: {
						message: "Password validation error. Please check your password entries match"
					}
				}).then(function(modal) {
					modal.close.then(function(result) {
						return;
					});
				});
				return;
			}
		}
		
		if($scope.edit){
			if($scope.person.password == ""){
				delete $scope.person.password;
			}
			console.log($scope.person);
			PeopleModel.update($state.params.id, $scope.person).then(function($data){
				$state.go('people', {});
			}, function (error) {
				console.error(error);
			});
		}else{
			PeopleModel.new($scope.person).then(function (data) {
				// if success, redirect to user state
				$state.go('people', {});
			}, function (error) {
				console.error(error);
			});
		}
	};

	$scope.validatePassword = function(){
		if($scope.person.password == "" || $scope.confirmPassword == "" || $scope.person.password != $scope.confirmPassword){
			return false;
		}else{
			return true;
		}
	};
});