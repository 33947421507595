
'use strict';
angular.module('app.directives').directive('peopleTable', function(){

	return {
		restrict: 'E',
		scope: {
			people: '=',
			searchParams: '=',
			roleParams: '=',
			depotParams: '='
		},
		templateUrl: "/views/app/directives/people-table/people-table.html",
		link: function($scope, element, attrs) {

		},
		controller: function($scope, PeopleModel, ModalService){
			$scope.pageSize = 10;
			$scope.currentPage = 1;

			$scope.getPeople = function() {
				PeopleModel.all().then(function($data){
					$scope.people = $data;
				}, function(error) {
					console.error(error);
				});
			};
			$scope.getPeople();

			$scope.deletePerson = function($id){
				ModalService.showModal({
					templateUrl: "/views/app/modals/delete-modal/delete-modal.html",
					controller: "DeleteModalController",
					inputs: {
						deleteMethod: function(id, model){
							model.delete(id).then(function($data){
								$scope.getPeople();
							}, function (error) {
								console.error(error);
							})
						},
						id: $id,
						model: PeopleModel
					}
				});
			};
		}
	};
});

angular.module('app.filters').filter('roleFilter', function() {
	//Role filter used on people table. selectedRole is passed in via $scope variable in view.
	//Iterate through all people in table and filter out those who dont have the selectedRole
	return function(input, selectedRole) {
		if(selectedRole == undefined){
			return input;
		}

		var output = input.filter(function(person){
			return person.roles.filter(function(role) {
					return role.id == selectedRole.id;
				}).length > 0;
		});
		return output;
	};
});

angular.module('app.filters').filter('depotFilter', function() {
	//Depot used on people table. selectedDepot is passed in via $scope variable in view.
	//Iterate through all people in table and filter out those who dont have the selectedDepot
	return function(input, selectedDepot) {
		if(selectedDepot == undefined){
			return input;
		}

		var output = input.filter(function(person){
			return person.depots.filter(function(depot) {
				return depot.id == selectedDepot.id;
			}).length > 0;
		});
		return output;
	};
});