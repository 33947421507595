(function () {
	'use strict';

	angular.module('app.services')
		.factory('FileModel', function($q, $http) {

			var methods = {

				    uploadUserImage: function(file) {
				        var d = $q.defer();

				        var fd = new FormData()
				        fd.append('file', file);
				        var xhr = new XMLHttpRequest()

				        xhr.addEventListener('error', function(result) {
					    	d.reject(result);
					    }, false)

				        xhr.onreadystatechange = function() {
				            if (xhr.readyState == XMLHttpRequest.DONE) {
				                d.resolve(JSON.parse(xhr.responseText));
				            }
				        }
				        xhr.open('POST', '/api/v1/cms/files');
				        xhr.setRequestHeader("Authorization", 'Bearer ' + window.localStorage.getItem('satellizer_token'));
				        xhr.send(fd);

				        return d.promise;
				    }

			};

			return methods;
		});
})();