
'use strict';
angular.module('app.directives').directive('configurationTable', function(){

	return {
		restrict: 'E',
		scope: {
			configurations: '=',
			searchParams: '='
		},
		templateUrl: "/views/app/directives/configuration-table/configuration-table.html",
		link: function ($scope, element, attrs) {

		},
		controller: function($scope, ConfigurationModel, ModalService){
			$scope.pageSize=10;
			$scope.currentPage = 1;

			$scope.getConfigurations = function() {
				ConfigurationModel.all().then(function($data){
					$scope.configurations = $data;
				}, function (error) {
					console.error(error);
				});
			};
			$scope.getConfigurations();

			$scope.deleteConfiguration = function($id){
				ModalService.showModal({
					templateUrl: "/views/app/modals/delete-modal/delete-modal.html",
					controller: "DeleteModalController",
					inputs: {
						deleteMethod: function(id, model){
							model.delete(id).then(function($data){
								$scope.getConfigurations();
							}, function (error) {
								console.error(error);
							})
						},
						id: $id,
						model: ConfigurationModel
					}
				});

			}
		}
	};

});